/** @format */

.SearchByMakeContainer-module__moreLink {
  border: solid 1px #154f9c;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  /* max-height: 56px; */
  max-height: 38px;
  padding: 0;
  max-width: 100px;
  min-width: 100px;
  /* margin: 0 10px; */
  margin-top: 7px;
}

.SearchByMakeContainer-module__moreLink > span {
  color: #154f9c !important;
  font-weight: normal;
}

body[dir="rtl"] .SearchByMakeContainer-module__moreLink {
  margin-right: 10px;
  margin-left: 20px;
}

body[dir="ltr"] .SearchByMakeContainer-module__moreLink {
  margin-left: 10px;
  margin-right: 20px;
}

body[dir="ltr"] .SearchByMakeContainer-module__moreLink img {
  transform: rotateY(180deg);
}

.SearchByMakeContainer-module__carLogoCont {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.SearchByMakeContainer-module__carLogoCont > img {
  width: 90px;
  height: auto;
}

.SearchByMakeContainer-module__makeName {
  color: #154f9c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.SearchByMakeContainer-module__underLine,
.SearchByMakeContainer-module__underLine:hover,
.SearchByMakeContainer-module__underLine:focus {
  text-decoration: underline;
}

.SearchByMakeContainer-module__brands_container {
  max-width: 760px;
  margin: 0 auto;
  margin-top: 70px;
}

.SearchByMakeContainer-module__makesWithLogo {
  display: flex;
  flex-wrap: wrap;
  max-width: 760px;
  margin: 0 auto;
  margin-top: 50px;
  justify-content: space-between;
}

.SearchByMakeContainer-module__singleLogoMake {
  justify-content: flex-end;
}

.SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake {
  width: calc((100% - 20px) / 4);
  margin-bottom: 20px;
  max-width: 176px;
}
.SearchByMakeContainer-module__viewAllMakes {
  color: var(--blueColor);
  border: solid 1px var(--blueColor);
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: bold;
  width: 190px;
  font-size: 16px;
  display: block;
  outline: none !important;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.SearchByMakeContainer-module__allCarsMakeText {
  margin-top: 30px;
  display: none;
}

.SearchByMakeContainer-module__allCarsMakeText > h3 {
  text-align: center;
  color: var(--blueColor);
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 50px;
}

.SearchByMakeContainer-module__allCarsMakeText > ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 860px;
  margin: 0 auto;
}

.SearchByMakeContainer-module__allCarsMakeText > ul > li {
  width: calc(100% / 5);
  text-align: center;
  margin-bottom: 40px;
}

.SearchByMakeContainer-module__allCarsMakeText > ul > li > a {
  font-size: 18px;
  color: var(--blueColor);
}

.SearchByMakeContainer-module__allCarsMakeText {
  display: block;
}

@media screen and (min-width: 1025px) {
  .SearchByMakeContainer-module__brands_container > h2 {
    color: #154f9c;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    padding-bottom: 16px;
    position: relative;
  }

  .SearchByMakeContainer-module__brands_container > h2::after {
    content: "";
    width: 104px;
    height: 5px;
    background-color: #ec6234;
    position: absolute;
    left: calc(50% - 104px / 2);
    bottom: 0px;
  }

  .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer > * {
    width: calc((100% - 20px) / 4);
    max-width: 176px;
    border-radius: 4px;
    border: 1px solid #cdd1d4;
    background: #fff;
    padding-top: 16px;
    padding-bottom: 14px;
    margin-bottom: 20px;
  }
  body[dir="rtl"] .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer > * {
    margin-left: calc(15px / 2 + 10px / 2);
  }

  body[dir="ltr"] .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer > * {
    margin-right: calc(15px / 2 + 10px / 2);
  }
  body[dir="rtl"]
    .SearchByMakeContainer-module__brands_container
    > .SearchByMakeContainer-module__brands_makesContainer
    > *:nth-child(4n + 4) {
    margin-left: 0;
  }

  body[dir="ltr"]
    .SearchByMakeContainer-module__brands_container
    > .SearchByMakeContainer-module__brands_makesContainer
    > *:nth-child(4n + 4) {
    margin-right: 0;
  }
  .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer img {
    height: auto;
    width: 55px;
  }

  .SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake:nth-child(n + 9) {
    display: none;
  }
}

.SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer .SearchByMakeContainer-module__imgContainer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
}

.SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer h3 {
  color: #154f9c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin-top: 16px;
}

.SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__button {
  margin: 0 auto;
  margin-top: 16px;
  width: auto;
  height: auto;
  padding: 12px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .SearchByMakeContainer-module__brands_container {
    margin-top: 34px;
  }

  .SearchByMakeContainer-module__carLogoCont > img,
  .SearchByMakeContainer-module__imgContainer img {
    width: 55%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  /* .makeName {
    display: none;
  } */

  .SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake {
    /* min-width: 56px; */
    min-width: 88px;
    /* height: 56px; */
    height: auto;
    /* background-color: #fafafa; */
    border: none;
    margin: 0;
  }
  body[dir="rtl"] .SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake + .SearchByMakeContainer-module__singleLogoMake {
    margin-right: 8px;
  }
  body[dir="ltr"] .SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake + .SearchByMakeContainer-module__singleLogoMake {
    margin-left: 8px;
  }

  .SearchByMakeContainer-module__makesWithLogo > .SearchByMakeContainer-module__singleLogoMake > div > span {
    display: none;
  }
  .SearchByMakeContainer-module__makesWithLogo {
    flex-wrap: nowrap;
    overflow-y: auto;
    margin-top: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .SearchByMakeContainer-module__allCarsMakeText > ul > li:first-child {
    display: none;
  }

  .SearchByMakeContainer-module__allCarsMakeText > ul > li {
    width: calc(100% / 2);
  }

  .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer h3 {
    color: #484848;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 8px;
  }

  .SearchByMakeContainer-module__brands_container > .SearchByMakeContainer-module__brands_makesContainer .SearchByMakeContainer-module__imgContainer {
    height: 56px;
    border-radius: 5px;
    background: #fafafa;
    overflow: hidden;
  }
}
