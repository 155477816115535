.SubmitButton-module__btn {
  height: 58px;
  width: 318px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #154f9c;
  font-size: 16px;
  line-height: 24px;
  color: #ffff;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.SubmitButton-module__loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 100%;
  border-top: 5px solid transparent;
  animation: SubmitButton-module__load-animate infinite linear 1s;
}

.SubmitButton-module__disabled {
  opacity: 50%;
  cursor: not-allowed;
}

@keyframes SubmitButton-module__load-animate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    transform: rotate(360deg);
  }
}
