.SlideContent-module__container {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 40px;
}

.SlideContent-module__container > div {
  width: calc(100% / 4 - 23px / 2);
  border-radius: 8px;
  overflow: hidden;
  margin: 0 calc(20px / 2);
}

.SlideContent-module__pCont {
  margin-top: 65px;
  /* margin-bottom: 70px; */
}

@media screen and (min-width: 1025px) {
  .SlideContent-module__has3 {
    justify-content: center;
  }

  .SlideContent-module__has3 > div:nth-child(2) {
    margin: 0 calc(23px / 2);
  }
  body[dir="rtl"] .SlideContent-module__has3 > div:nth-child(3) {
    margin-left: calc(23px / 2);
  }
  body[dir="ltr"] .SlideContent-module__has3 > div:nth-child(3) {
    margin-right: calc(23px / 2);
  }
}

@media screen and (max-width: 1024px) {
  .SlideContent-module__container > div {
    min-width: 240px;
    max-width: 240px;
  }
  .SlideContent-module__container {
    overflow-y: auto;
    margin-top: 20px;
  }
  body[dir="rtl"] .SlideContent-module__container > div + div {
    margin-right: 12px;
  }

  body[dir="ltr"] .SlideContent-module__container > div + div {
    margin-left: 12px;
  }
  .SlideContent-module__pCont {
    margin-top: 34px;
    /* margin-bottom: 34px; */
  }

  body[dir="rtl"] .SlideContent-module__container > div:last-of-type {
    margin-left: 20px;
  }

  body[dir="ltr"] .SlideContent-module__container > div:last-of-type {
    margin-right: 20px;
  }
}

.SlideContent-module__cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SlideContent-module__cardContainer > img,.SlideContent-module__cardContainer >span> img {
  width: 100%;
}
