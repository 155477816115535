.Layout-module__imageBlueBg {
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Layout-module__bg {
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -3px;
  width: 70px;
}

.Layout-module__imageinBox {
  position: absolute;
  top: -35px;
  right: calc(50% - 72px / 2);
}

.Layout-module__contUs {
  padding-top: 52px !important;
}

.Layout-module__botomNavContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 90px; */
  min-height: 64px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  -webkit-box-shadow: 0px -2px 6px rgb(0 0 0 / 30%);
  z-index: 9999;
}

.Layout-module__botomNavContainer > a,
.Layout-module__botomNavContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Layout-module__botomNavContainer > a > span,
.Layout-module__botomNavContainer > div > span {
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
  color: #6c7a8d;
}

.Layout-module__botomNavContainer > *:not(.Layout-module__activeNav) > img[src*="Blue"] {
  display: none;
}

.Layout-module__botomNavContainer > .Layout-module__activeNav > img[src*="Blue"] + img {
  display: none;
}

.Layout-module__botomNavContainer > .Layout-module__activeNav > span {
  color: #154f9c;
}

.Layout-module__activeNav,
.Layout-module__activeNav * {
  pointer-events: none;
}

.Layout-module__hasAuthPad {
  padding-bottom: 44px;
}

.Layout-module__togglThemeBtn {
  width: 140px;
  position: fixed;
  top: 90px;
  right: -120px;
  transition: 0.5s;
  z-index: 9999999999;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 30%);
}

.Layout-module__togglThemeBtn:hover{
  right: 0;

}


@media screen and (max-width: 1024px) {
  .Layout-module__hasNavBar {
    padding-bottom: 70px !important;
  }
}
