.HeroArea2-module__nextSlide,
.HeroArea2-module__prevSlide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
  opacity: 1;
}

.HeroArea2-module__activeSlide {
  position: relative;
  z-index: 999;
}

.HeroArea2-module__singleslide {
  transition: 0.3s;
}

.HeroArea2-module__sliderConainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  /* background-color: black; */
  overflow: hidden;
}

body[dir="rtl"] .HeroArea2-module__prevSlide {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="ltr"] .HeroArea2-module__prevSlide {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="rtl"] .HeroArea2-module__nextSlide {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="ltr"] .HeroArea2-module__nextSlide {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
}

body[dir="rtl"] .HeroArea2-module__prevSlide.HeroArea2-module__actAsLst {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="ltr"] .HeroArea2-module__prevSlide.HeroArea2-module__actAsLst {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="rtl"] .HeroArea2-module__nextSlide.HeroArea2-module__actAsfirst {
  transform: translate(100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

body[dir="ltr"] .HeroArea2-module__nextSlide.HeroArea2-module__actAsfirst {
  transform: translate(-100%, 0) scale(1) rotateY(0);
  opacity: 1;
  pointer-events: none;
  z-index: 99;
}

.HeroArea2-module__RightArr {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 34px / 2);
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 999;
}

body[dir="rtl"] .HeroArea2-module__RightArr {
  right: 25px;
}

body[dir="ltr"] .HeroArea2-module__RightArr {
  left: 25px;
  transform: rotate(180deg);
}

.HeroArea2-module__leftArr {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 34px / 2);
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 999;
}

body[dir="ltr"] .HeroArea2-module__leftArr {
  right: 25px;
  transform: rotate(180deg);
}

body[dir="rtl"] .HeroArea2-module__leftArr {
  left: 25px;
}

.HeroArea2-module__dots {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  /* display: none; */
}

.HeroArea2-module__dots > li {
  height: 6px;
  border-radius: 3px;
  background-color: #c5d2e4;
  width: 24px;
  transition: 0.3s;
  margin: 0 calc(4px / 2);
  cursor: pointer;
}

.HeroArea2-module__dots > li.HeroArea2-module__active {
  background-color: #fe561c;
  width: 40px;
}

.HeroArea2-module__sliderConainer [class*="SlideLinkContainer"] {
  position: absolute;
  width: 100%;
  z-index: 2;
  /* bottom: 80px; */
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeroArea2-module__sliderConainer [class*="SlideLinkContainer"] > a {
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-weight: bold;
}

.HeroArea2-module__activeSlide+.HeroArea2-module__nextSlide {
  z-index: 9 !important;
}
