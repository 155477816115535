.BoxLoading-module__animatedBackground {
  animation: BoxLoading-module__loading 1s linear infinite alternate;
  background-size: 1200px 100px;
  min-height: 10px;
  position: relative;
  width: 100%;
  border-radius: 7px;
  margin: 0 0 20px;
}

div[class*="allCarsResult"] .BoxLoading-module__animatedBackground {
  min-height: 270px !important;
}

.BoxLoading-module__animatedBackground {
  animation-name: BoxLoading-module__loading;
}

@keyframes BoxLoading-module__loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}



